import React, {Component, createRef} from "react";
import {connect} from "react-redux";
import socketIOClient from "socket.io-client";
import LocalStorage from "../../util/localStorage";
import Participant from "./participant"
import FieldTextarea from "../../components/field-textarea";
import Button from "../../components/button";
import {WebRtcPeerRecvonly, WebRtcPeerSendonly} from "./utils/WebRtcPeer";
import FieldSelect from "../../components/field-select";
import {getResource, getResourceDetails} from "../../data/actions/resource";
import Resources from "../../data/services/resources";
import LoaderSmall from "../../components/loader-small";
import {Link} from "react-router-dom";
import {getProp, ifContainsURL, isValidURL, validURL} from "../../util/util";
import Mentions from 'rc-mentions';

const {Option} = Mentions;
const MEDIA_PROXY_URL = "https://media.codetest.space:4001"; // http://159.89.25.163/
//const MEDIA_PROXY_URL = "https://socket.com:4001";
let emojis = require('./emojis.json');
const DEBUG = true;

function formatBytes(bytes, decimals = 2) {
    if (bytes === 0 || !bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

class RoomView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // Chat
            chatValue: "",
            showAutocomplete: false,
            selectedSuggestedItem: "",
            chatCollapse: true,

            // Participants info map
            participants: {},

            // Chat message list
            chat: [],

            // Room state
            joinedRoom: false,

            // Available media
            audioInputSelect: [],
            audioOutputSelect: [],
            videoSelect: [],

            videoAvailable: false,
            audioAvailable: false,
            screenAvailable: true,

            // Local media state
            camera: true,
            mic: true,
            screenSharing: false,
            // Local media configuration (selected devices etc)
            configuration: {
                audioInputSelect: "",
                audioOutputSelect: "",
                videoSelect: ""
            },

            // Misc
            streamStats: null,
            speaking: false,
            speakingMap: {},

            isInFullScreen: false,
            loadingRoom: true,

            // Permisije poruke
            cameraPermission: false,
            microphonePermission: false,

            // Emojis
            selectedEmojiGroup: "Smileys & People",
            emojisToggler: false,

            // Flags
            cameraFlag: false,
            micFlag: false,


            addTilt: false,


            screenResizeToggler: false
        }

        this.setupVideoRef = createRef()

        this.myVideoRef = createRef()

        this.videoRefs = createRef();
        this.videoRefs.current = {}

        this.audioRefs = createRef();
        this.audioRefs.current = {}

        // Participants WebRtc connection(s) map
        this.participants = {};

        this.setupStream = null;

        this.autocompleteRef = createRef();
        this.autocompleteDisplayRef = createRef();
    }

    async componentDidMount() {
        window.addEventListener('resize', this.handleResize)
        if (window.innerWidth < 975) {
            this.setState({screenResizeToggler: true, chatCollapse: false})
        }
        console.log("RoomView:componentDidMount")
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.Room
        }));

        await this.getPermissions();
        console.log("AUDIO: ", this.state.audioAvailable)
        console.log("VIDEO: ", this.state.videoAvailable)
        navigator.permissions.query({name: 'camera'}).then((result) => {
            if (result.state === 'granted') {
                this.setState({cameraPermission: true})
            } else if (result.state === "prompt") {
                this.setState({cameraPermission: false})
            }
        });
        navigator.permissions.query({name: 'microphone'}).then((result) => {
            if (result.state === 'granted') {
                this.setState({microphonePermission: true})
            } else if (result.state === "prompt") {
                this.setState({microphonePermission: false})
            }
        });

        navigator.mediaDevices.enumerateDevices()
            .then((deviceInfos) => {
                console.log(deviceInfos);
                let audioInputSelect = [];
                let audioOutputSelect = [];
                let videoSelect = [];

                for (let i = 0; i !== deviceInfos.length; ++i) {
                    const deviceInfo = deviceInfos[i];

                    let deviceId = deviceInfo.deviceId;

                    if (deviceInfo.kind === 'audioinput') {
                        audioInputSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1)
                        });
                    } else if (deviceInfo.kind === 'audiooutput') {
                        audioOutputSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Speaker ' + (audioOutputSelect.length + 1)
                        });
                    } else if (deviceInfo.kind === 'videoinput') {
                        videoSelect.push({
                            id: deviceId,
                            value: deviceInfo.label || 'Camera ' + (videoSelect.length + 1)
                        });
                    }
                }

                this.setState({
                    configuration: {
                        audioInputSelect: audioInputSelect.length > 0 ? audioInputSelect[0].id : "",
                        audioOutputSelect: audioOutputSelect.length > 0 ? audioOutputSelect[0].id : "",
                        videoSelect: videoSelect.length > 0 ? videoSelect[0].id : "",
                    },
                    audioInputSelect: audioInputSelect,
                    audioOutputSelect: audioOutputSelect,
                    videoSelect: videoSelect,
                    screenAvailable: true
                }, () => {
                    this.updateSetupStreamState();
                })
            })
            .catch((err) => {
                console.log(err)
            });

        window.addEventListener('beforeunload', this.leaveRoom)

        document.addEventListener("mousedown", this.handleClickOutsideAutocomplete)
        return () => {
            document.removeEventListener("mousedown", this.handleClickOutsideAutocomplete)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isAudioParticipantEnabled = getProp(this.props.resource, "data.room.EnableAudioParticipant", 0);
        const isVideoParticipantEnabled = getProp(this.props.resource, "data.room.EnableVideoParticipant", 0);
        const currentUserRoleID = getProp(this.props.resource, "data.user.RoleID", 2)

        if (isVideoParticipantEnabled == 0 && currentUserRoleID == 1 && !this.state.cameraFlag) {
            this.setState({camera: false, cameraFlag: true})
        }
        if (isAudioParticipantEnabled == 0 && currentUserRoleID == 1 && !this.state.micFlag) {
            this.setState({mic: false, micFlag: true})
        }
        document.addEventListener('fullscreenchange', this.fullScreenHandler);
        document.addEventListener('webkitfullscreenchange', this.fullScreenHandler);
        document.addEventListener('mozfullscreenchange', this.fullScreenHandler);
        document.addEventListener('MSFullscreenChange', this.fullScreenHandler);
        const objDiv = document.getElementById('chat-wrapper');
        if (!!objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    componentWillUnmount() {
        console.log("RoomView:componentWillUnmount")
        this.leaveRoom();
    }

    handleClickOutsideAutocomplete = (event) => {
        const {current: wrap} = this.autocompleteDisplayRef
        if (wrap && !wrap.contains(event.target)) {
            this.setState({showAutocomplete: false})
        }
    };

    setupRoom = () => {
        this.socket = socketIOClient(MEDIA_PROXY_URL);

        this.socket.on('connect', () => {
            console.log('ws connect success');
            this.joinRoom();
        });

        this.socket.on('message', parsedMessage => {
            console.info('Room:Received message: ' + parsedMessage.id);

            this.setState({addTilt: true}, ()=> {
                setTimeout(()=> {
                    this.setState({addTilt: false})
                },1400)
            })

            switch (parsedMessage.id) {
                case 'existingParticipants':
                    this.onExistingParticipants(parsedMessage);
                    break;
                case 'newParticipantArrived':
                    this.onNewParticipant(parsedMessage);
                    break;
                case 'participantLeft':
                    this.onParticipantLeft(parsedMessage);
                    break;
                case 'receiveVideoAnswer':
                    this.receiveVideoResponse(parsedMessage);
                    break;
                case 'iceCandidate':
                    this.participants[parsedMessage.name].rtcPeer.addIceCandidate(parsedMessage.candidate, function (error) {
                        if (error) {
                            console.error("Error adding candidate: " + error);

                        }
                    });
                    break;
                case 'chat':
                    let chats = this.state.chat;
                    chats.push({
                        name: parsedMessage.name,
                        msg: parsedMessage.msg
                    });
                    this.setState({
                        chat: chats
                    });
                    break;
                case 'userStateUpdate':
                    let participants = this.state.participants;
                    console.log(parsedMessage)
                    participants[parsedMessage.name].mic = parsedMessage.data.mic;
                    participants[parsedMessage.name].camera = parsedMessage.data.camera;
                    participants[parsedMessage.name].role = parsedMessage.data.role;
                    this.setState({
                        participants: participants
                    })
                    break;
                default:
                    console.error('Unrecognized message', parsedMessage);
            }
        });

        this.props.dispatch(getResourceDetails({
            user: LocalStorage.get("user"),
            query: {
                id: this.props.match.params.id
            },
            resource: Resources.RoomChat
        }));
    };

    // - Socket message callbacks

    onNewParticipant = (request) => {
        const participants = this.state.participants;
        participants[request.name] = new Participant(request.name, request.role, request.camera, request.mic);

        this.setState({
            participants: participants
        }, () => {
            this.participants[request.name] = this.state.participants[request.name];

            const audio = this.audioRefs.current[request.name];
            const video = this.videoRefs.current[request.name];

            this.receiveAudioVideo(this.participants[request.name], audio, video);
        })
    };

    onExistingParticipants = (msg) => {
        console.log(this.getName() + " registered in room " + this.getId());

        this.participants[this.getName()] = new Participant(this.getName(), this.isPresenter() ? 2 : 1, this.state.camera, this.state.mic)
        const participants = this.state.participants;

        msg.data.forEach((sender) => {
            participants[sender.name] = new Participant(sender.name, sender.role, sender.camera, sender.mic);
        });

        this.setState({
            loadingRoom: false,
            participants: participants
        }, () => {
            // Setup local stream
            const video = this.myVideoRef.current;
            let constraints;
            if (this.participants[this.getName()].role == 2) {
                constraints = {
                    audio: this.state.audioAvailable ? {
                        deviceId: {exact: this.state.configuration.audioInputSelect},
                    } : false,
                    video: this.state.videoAvailable ? {
                        deviceId: {exact: this.state.configuration.videoSelect},
                        advanced: [
                            {frameRate: {min: 24, max: 30}},
                            {height: {min: 360, max: 1280}},
                            {width: {min: 640, max: 720}},
                            {aspectRatio: {exact: 1.77778}}
                        ]
                    } : false
                };
            } else {
                constraints = {
                    audio: this.state.audioAvailable ? {
                        deviceId: {exact: this.state.configuration.audioInputSelect},
                    } : false,
                    video: this.state.videoAvailable ? {
                        deviceId: {exact: this.state.configuration.videoSelect},
                        advanced: [
                            {frameRate: {min: 15, max: 24}},
                            {height: {min: 144, max: 144}},
                            {width: {min: 256, max: 256}},
                            {aspectRatio: {exact: 1.77778}}
                        ]
                    } : false
                };
            }

            let options = {
                localVideo: video,
                mediaConstraints: constraints,
                onconnectionstatechange: (state) => {
                    console.log(state)
                },
                onicecandidate: (candidate, wp) => {
                    console.log("Local candidate" + candidate);

                    const message = {
                        id: 'onIceCandidate',
                        candidate: candidate,
                        sender: this.getName()
                    };
                    this.sendMessage(message);
                },
                onSpeechDetection: ((speak) => {
                    this.setState({speaking: speak})
                })
            };

            this.participants[this.getName()].rtcPeer = new WebRtcPeerSendonly(options,
                (error) => {
                    if (error) {
                        return console.error(error);
                    }
                    this.participants[this.getName()].rtcPeer.generateOffer((error, offerSdp, wp) => {
                        const msg = {
                            id: "receiveVideoFrom",
                            sender: this.getName(),
                            sdpOffer: offerSdp
                        };
                        this.sendMessage(msg);
                    });

                    if (!this.state.mic) {
                        this.participants[this.getName()].rtcPeer.micOff();
                    }
                    if (!this.state.camera) {
                        this.participants[this.getName()].rtcPeer.cameraOff();
                    }
                    this.broadcastUserStateUpdate();
                });

            // Setup remote streams
            Object.values(this.state.participants).forEach((participant, i) => {
                this.participants[participant.name] = participant;

                const audio = this.audioRefs.current[participant.name];
                const video = this.videoRefs.current[participant.name];

                this.receiveAudioVideo(this.participants[participant.name], audio, video);
            });
        });

    };

    onParticipantLeft = (request) => {
        console.log('Participant ' + request.name + ' left');

        // Update UI
        const participants = this.state.participants;
        if (participants[request.name]) {
            delete participants[request.name];
            this.setState({
                participants: participants
            }, () => {
                // Clear rtc connection
                if (this.participants[request.name]) {
                    this.participants[request.name].rtcPeer.dispose();
                    delete this.participants[request.name];
                }
            });
        }
    }

    // - User actions

    fullScreenHandler = () => {
        this.setState({isInFullScreen: !this.state.isInFullScreen})
    };

    enterRoom = () => {
        this.setState({
            joinedRoom: true
        }, () => {
            if (this.setupStream) {
                this.setupStream.getTracks().forEach((track) => {
                    track.stop()
                });
                this.setupStream = null;
            }
            this.setupRoom();
        })
    };

    leaveRoom = (goBack = false) => {
        console.log("room:leaveRoom")
        if (this.socket) {
            this.sendMessage({
                'id': 'leaveRoom'
            });

            console.log(this.participants)
            for (const key in this.participants) {
                this.participants[key].rtcPeer.dispose();
            }

            this.socket.close();

            this.socket.disconnect();

            goBack && this.props.history.push("/conference/" + this.props.resource.data.conference.ConferenceID);
        }

        if (this.setupStream) {
            this.setupStream.getTracks().forEach((track) => {
                track.stop()
            });
        }
    };

    sendChat = (msg) => {
        this.sendMessage({
            id: "chat",
            name: this.getName(),
            roomName: this.getId(),
            msg: msg,
            ToID: this.getId(),
            FromID: LocalStorage.get('user').Contact.user_id
        })
    };

    screenShareToggle = () => {
        this.setState({
            screenSharing: !this.state.screenSharing
        }, () => {
            if (this.state.screenSharing) {
                this.participants[this.getName()].rtcPeer.screenShareOn(() => {
                    console.log("Screen share end");
                    this.setState({
                        screenSharing: false
                    });
                });
            } else {
                this.participants[this.getName()].rtcPeer.screenShareOff();
            }
        })
    };

    cameraToggle = () => {
        this.setState({
            camera: !this.state.camera
        }, () => {
            if (this.state.joinedRoom) {
                if (this.state.camera) {
                    this.participants[this.getName()].rtcPeer.cameraOn();
                } else {
                    this.participants[this.getName()].rtcPeer.cameraOff();
                }
                this.broadcastUserStateUpdate()
            } else {
                if (!!this.setupStream) {
                    this.setupStream.getVideoTracks()[0].enabled = this.state.camera
                }
            }
        })
    };

    micToggle = () => {
        this.setState({
            mic: !this.state.mic
        }, () => {
            if (this.state.joinedRoom) {
                if (this.state.mic) {
                    this.participants[this.getName()].rtcPeer.micOn();
                } else {
                    this.participants[this.getName()].rtcPeer.micOff();
                }
                this.broadcastUserStateUpdate()
            } else {
                if (!!this.setupStream) {
                    this.setupStream.getAudioTracks()[0].enabled = this.state.mic
                }
            }
        })
    }

    // - WebRtc Helpers

    joinRoom = () => {
        const message = {
            id: 'joinRoom',
            name: this.getName(),
            roomName: this.getId(),
            token: LocalStorage.get('user').access_token
        };
        this.sendMessage(message);
    };

    getPermissions = async () => {
        await navigator.mediaDevices.getUserMedia({video: true})
            .then((stream) => {
                this.setState({
                    videoAvailable: true
                });
                stream.getTracks().forEach((track) => {
                    track.stop()
                });
            })
            .catch(() => this.setState({
                videoAvailable: false
            }))

        await navigator.mediaDevices.getUserMedia({audio: true})
            .then(() => this.setState({
                audioAvailable: true
            }))
            .catch(() => this.setState({
                audioAvailable: false
            }))
    };

    receiveVideoResponse = (result) => {
        this.participants[result.name].rtcPeer.processAnswer(result.sdpAnswer, (error) => {
            if (error) return console.error(error);
        });
    };

    receiveAudioVideo = (participant, audio, video) => {
        const options = {
            remoteAudio: audio,
            remoteVideo: video,
            onicecandidate: (candidate, wp) => {
                console.log("Local candidate" + candidate);

                const message = {
                    id: 'onIceCandidate',
                    candidate: candidate,
                    sender: participant.name
                };
                this.sendMessage(message);
            },
            onSpeechDetection: ((speak) => {
                let speakingMap = this.state.speakingMap;
                speakingMap[participant.name] = speak;
                this.setState({speakingMap: speakingMap})
            })
        };

        this.participants[participant.name].rtcPeer = new WebRtcPeerRecvonly(options,
            (error) => {
                if (error) {
                    return console.error(error);
                }
                this.participants[participant.name].rtcPeer.generateOffer((error, offerSdp, wp) => {
                    const msg = {
                        id: "receiveVideoFrom",
                        sender: participant.name,
                        sdpOffer: offerSdp
                    };
                    this.sendMessage(msg);
                });
            }
        );
    };

    broadcastUserStateUpdate = () => {
        this.sendMessage({
            id: 'userStateUpdate',
            mic: this.state.audioAvailable ? this.state.mic : false,
            camera: this.state.videoAvailable ? this.state.camera : false
        })
    };

    // - Helpers

    updateSetupStreamMedia = (name, value) => {
        const config = this.state.configuration;
        config[name] = value;
        this.setState({
            configuration: config
        }, this.updateSetupStreamState)
    };

    updateSetupStreamState = () => {
        window.navigator.mediaDevices.getUserMedia({
            audio: this.state.audioAvailable ? {
                deviceId: {exact: this.state.configuration.audioInputSelect},
            } : false,
            video: this.state.videoAvailable ? {
                deviceId: {exact: this.state.configuration.videoSelect},
                advanced: [
                    {frameRate: {min: 24}},
                    {height: {min: 360}},
                    {width: {min: 640}},
                    {frameRate: {max: 24}},
                    {width: {max: 1280}}, {height: {max: 720}},
                    {aspectRatio: {exact: 1.77778}}
                ]
            } : false
        })
            .then((stream) => {
                this.setupStream = stream;
                this.setupVideoRef.current.srcObject = stream
            })
            .catch((err) => {
                console.log(err)
            });
    };

    isPresenter = () => {
        return (this.props.resource.data.user.RoleID == 2 || this.props.resource.data.user.RoleID == 3);
    };

    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getName = () => {
        return LocalStorage.get('user').username;
    };

    sendMessage = (message) => {
        //console.log('Sending message: ' + JSON.stringify(message));
        this.socket.emit('message', message);
    };

    addToAudioRef = (el, name) => {
        if (el) {
            this.audioRefs.current[name] = (el)
        }
    };

    addToVideoRef = (el, name) => {
        if (el) {
            this.videoRefs.current[name] = (el)
        }
    };

    debug = (name) => {
        if (DEBUG) {
            if (!!this.state.streamStats) {
                this.state.streamStats.nomore();
            }
            if (name !== this.state.steamStatsName) {
                this.participants[name].rtcPeer.getStats((result) => {
                    this.setState({
                        streamStats: result,
                        steamStatsName: name
                    })
                });
            } else {
                this.setState({
                    streamStats: null,
                    steamStatsName: null
                })
            }
        }
    };

    emoji = e => {
        let emojis = {
            'o/': '👋',
            '</3': '💔',
            '<3': '💗',
            '8-D': '😁',
            '8D': '😁',
            ':-D': '😁',
            '=-3': '😁',
            '=-D': '😁',
            '=3': '😁',
            '=D': '😁',
            'B^D': '😁',
            'X-D': '😁',
            'XD': '😁',
            'x-D': '😁',
            'xD': '😁',
            ':\')': '😂',
            ':\'-)': '😂',
            ':-))': '😃',
            '8)': '😄',
            ':)': '😄',
            ':-)': '😄',
            ':3': '😄',
            ':D': '😄',
            ':]': '😄',
            ':^)': '😄',
            ':c)': '😄',
            ':o)': '😄',
            ':}': '😄',
            ':っ)': '😄',
            '=)': '😄',
            '=]': '😄',
            '0:)': '😇',
            '0:-)': '😇',
            '0:-3': '😇',
            '0:3': '😇',
            '0;^)': '😇',
            'O:-)': '😇',
            '3:)': '😈',
            '3:-)': '😈',
            '}:)': '😈',
            '}:-)': '😈',
            '*)': '😉',
            '*-)': '😉',
            ':-,': '😉',
            ';)': '😉',
            ';-)': '😉',
            ';-]': '😉',
            ';D': '😉',
            ';]': '😉',
            ';^)': '😉',
            ':-|': '😐',
            ':|': '😐',
            ':(': '😒',
            ':-(': '😒',
            ':-<': '😒',
            ':-[': '😒',
            ':-c': '😒',
            ':<': '😒',
            ':[': '😒',
            ':c': '😒',
            ':{': '😒',
            ':っC': '😒',
            '%)': '😖',
            '%-)': '😖',
            ':-P': '😜',
            ':-b': '😜',
            ':-p': '😜',
            ':-Þ': '😜',
            ':-þ': '😜',
            ':P': '😜',
            ':b': '😜',
            ':p': '😜',
            ':Þ': '😜',
            ':þ': '😜',
            ';(': '😜',
            '=p': '😜',
            'X-P': '😜',
            'XP': '😜',
            'd:': '😜',
            'x-p': '😜',
            'xp': '😜',
            ':-||': '😠',
            ':@': '😠',
            ':-.': '😡',
            ':-/': '😡',
            ':/': '😡',
            ':L': '😡',
            ':S': '😡',
            ':\\': '😡',
            '=/': '😡',
            '=L': '😡',
            '=\\': '😡',
            ':\'(': '😢',
            ':\'-(': '😢',
            '^5': '😤',
            '^<_<': '😤',
            'o/\\o': '😤',
            '|-O': '😫',
            '|;-)': '😫',
            ':###..': '😰',
            ':-###..': '😰',
            'D-\':': '😱',
            'D8': '😱',
            'D:': '😱',
            'D:<': '😱',
            'D;': '😱',
            'D=': '😱',
            'DX': '😱',
            'v.v': '😱',
            '8-0': '😲',
            ':-O': '😲',
            ':-o': '😲',
            ':O': '😲',
            ':o': '😲',
            'O-O': '😲',
            'O_O': '😲',
            'O_o': '😲',
            'o-o': '😲',
            'o_O': '😲',
            'o_o': '😲',
            ':$': '😳',
            '#-)': '😵',
            ':#': '😶',
            ':&': '😶',
            ':-#': '😶',
            ':-&': '😶',
            ':-X': '😶',
            ':X': '😶',
            ':-J': '😼',
            ':*': '😽',
            ':^*': '😽',
            'ಠ_ಠ': '🙅',
            '*\\0/*': '🙆',
            '\\o/': '🙆',
            ':>': '😄',
            '>.<': '😡',
            '>:(': '😠',
            '>:)': '😈',
            '>:-)': '😈',
            '>:/': '😡',
            '>:O': '😲',
            '>:P': '😜',
            '>:[': '😒',
            '>:\\': '😡',
            '>;)': '😈',
            '>_>^': '😤',
        }
        let newData = e.split(" ")
        newData.map((item, i) => {
            Object.keys(emojis).map(emoji => {
                if (item == emoji) {
                    newData[i] = emojis[emoji]
                }
            })
        })
        this.setState({chatValue: newData.join(" ")})
    };

    setToFullscreen = id => {
        var element = document.getElementById(id);
        if (!window.fullScreen) {

            if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullScreen) {
                element.webkitRequestFullScreen();
            }
        } else {
            if (element.exitFullscreen) {
                element.exitFullscreen();
            } else if (element.webkitExitFullscreen) {
                element.webkitExitFullscreen();
            } else if (element.msExitFullscreen) {
                element.msExitFullscreen();
            }
        }
    };

    openFullscreen = (id) => {
        const elem = document.getElementById(id);
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.msRequestFullscreen();
        }
        this.setState({isInFullScreen: true})
    };

    closeFullscreen = () => {
        this.setState({isInFullScreen: false})
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    fullScreenToggle = (param) => {
        if (document.fullscreenElement) {
            this.closeFullscreen()
        } else {
            this.openFullscreen(param)
        }
    };

    handleEnterClick = event => {
        if (event.keyCode == 13 && !event.shiftKey) {
            if (this.state.chatValue != "") {
                this.sendChat(this.state.chatValue);
                this.setState({
                    chatValue: ""
                })
            }
        }
    };

    handleResize = () => {
        if (window.innerWidth > 975) {
            this.setState({screenResizeToggler: false})
        } else {
            this.setState({screenResizeToggler: true})
        }
    };

    render() {
        const {translate} = this.props;
        const currentUserRoleID = getProp(this.props.resource, "data.user.RoleID", 0);
        const currentUserEmail = LocalStorage.get("user").email;
        const isAudioParticipantEnabled = getProp(this.props.resource, "data.room.EnableAudioParticipant", 0);
        const isVideoParticipantEnabled = getProp(this.props.resource, "data.room.EnableVideoParticipant", 0);

        const participants = Object.values(this.state.participants)
            .filter(participant => participant.role == 1)
            .map((participant, i) => {
                return (
                    <div style={{position: 'relative'}} className="participant-single" key={participant.name}>
                        <video
                            onClick={() => {
                                this.debug(participant.name);
                            }}
                            muted
                            playsInline autoPlay disablePictureInPicture
                            ref={(el) => this.addToVideoRef(el, participant.name)}
                            poster={"/images/logo.png"}
                        />
                        <audio
                            playsInline autoPlay
                            ref={(el) => this.addToAudioRef(el, participant.name)}/>
                        <div style={{padding: "3px"}} className="participant-name">{participant.name}</div>
                        {!!this.state.speakingMap[participant.name] && (
                            <div className="voice-container">
                                <div className="voice-animation"></div>
                            </div>
                        )}
                        <div className="inside-btn" style={{bottom: "40px"}}>
                            {!participant.mic && (
                                <div className="conf-icon-btn"><img style={{width: "20px"}}
                                                                                           src="/images/conf/mic_off.png"/>
                                </div>
                            )}
                            {!participant.camera && (
                                <div className="conf-icon-btn"><img style={{width: "20px"}}
                                                                                           src="/images/conf/kamera crveno precrtana.png"/>
                                </div>
                            )}
                        </div>
                    </div>
                )
            });
        const presenters = Object.values(this.state.participants)
            .filter(participant => (participant.role == 2))
            .map((participant, i) => {
                const slider = document.querySelector(".conference-participants");
                let isDown = false;
                let startX;
                let scrollLeft;
                let presentersLength = Object.values(this.state.participants).filter(participant => (participant.role == 2))
                presentersLength = presentersLength.length + (!!this.isPresenter() ? 1 : 0)
                if (slider) {
                    slider.addEventListener("mousedown", e => {
                        isDown = true;
                        slider.classList.add("active");
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    });
                    slider.addEventListener("mouseleave", () => {
                        isDown = false;
                        slider.classList.remove("active");
                    });
                    slider.addEventListener("mouseup", () => {
                        isDown = false;
                        slider.classList.remove("active");
                    });
                    slider.addEventListener("mousemove", e => {
                        if (!isDown) return;
                        e.preventDefault();
                        const x = e.pageX - slider.offsetLeft;
                        const walk = x - startX;
                        slider.scrollLeft = scrollLeft - walk;
                    });
                }
                let list = document.getElementById("chat-wrapper");
                if (list) {
                    list.scrollTop = list.offsetHeight;
                }

                return (
                    <div className={(!this.state.screenResizeToggler ? "participant-single mystream":"")} key={participant.name} style={{position: "relative"}}>
                        <div id={participant.name}>
                            <video
                                // style={{height: document.fullscreenElement ? "100vh" : (!!this.state.screenResizeToggler && presentersLength > 1) ? "calc(22vh + 20px)":"calc(45vh + 38px)"}}
                                id={participant.name}
                                className={(document.fullscreenElement ? "h-100" : "") + ((!!this.state.screenResizeToggler && presentersLength > 1) ? "video-half-row":"video-whole-row")}

                                onClick={() => {
                                    this.debug(participant.name);
                                }}
                                muted
                                playsInline autoPlay
                                ref={(el) => this.addToVideoRef(el, participant.name)}
                                poster="/images/logo.png"
                            >
                                <div onClick={() => this.fullScreenToggle(participant.name)} id="fullscreenbtn">
                                    {document.fullscreenElement ?
                                        <img style={{width: "40px"}} title="Smanji ekran"
                                             src="/images/conf/collapse.png" alt=""/>
                                        :
                                        <img src="/images/conf/fullscreen.png" title="Povecaj ekran" alt=""/>
                                    }
                                </div>
                            </video>

                            <audio
                                playsInline autoPlay
                                ref={(el) => this.addToAudioRef(el, participant.name)}/>
                            <div onClick={() => this.fullScreenToggle(participant.name)} id="fullscreenbtn">
                                {document.fullscreenElement ?
                                    <img title="Smanji ekran" style={{width: "40px"}} src="/images/conf/collapse.png"
                                         alt=""/>
                                    :
                                    <img title="Povecaj ekran" src="/images/conf/fullscreen.png" alt=""/>
                                }
                            </div>
                            <div className="conference-my-controls ">
                                <div className="conf-my-btn-wrapper">

                                    {this.state.videoAvailable && (
                                        <React.Fragment>
                                            {this.isPresenter() && (
                                                <div className="conf-icon-btn"
                                                     title={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                     onClick={this.screenShareToggle}>
                                                    <img
                                                        alt={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                        src={!this.state.screenSharing ? ("/images/conf/stop_share_screen_new.png") : ("/images/conf/share_screen_new.png")}/>
                                                </div>
                                            )}
                                            {currentUserRoleID == 1 && isVideoParticipantEnabled == 1 && (
                                                <div className="conf-icon-btn"
                                                     title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                     onClick={this.cameraToggle}>
                                                    <img
                                                        alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                        src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                </div>
                                            )}
                                            {currentUserRoleID != 1 && (
                                                <div className="conf-icon-btn"
                                                     title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                     onClick={this.cameraToggle}>
                                                    <img
                                                        alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                        src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {this.state.audioAvailable && (
                                        <React.Fragment>
                                            {currentUserRoleID == 1 && isAudioParticipantEnabled == 1 && (
                                                <div className="conf-icon-btn"
                                                     title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                     onClick={this.micToggle}>
                                                    <img
                                                        alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                        src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                </div>
                                            )}
                                            {currentUserRoleID != 1 && (
                                                <div className="conf-icon-btn"
                                                     title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                     onClick={this.micToggle}>
                                                    <img
                                                        alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                        src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="participant-name">{participant.name}</div>
                        {!!this.state.speakingMap[participant.name] && (
                            <div className="voice-container">
                                <div className="voice-animation"></div>
                            </div>
                        )}
                        <div className="inside-btn">
                            {!participant.mic && (
                                <div className="conf-icon-btn"><img
                                    src="/images/conf/mic_off.png"/></div>
                            )}
                            {!participant.camera && (
                                <div className="conf-icon-btn"><img
                                    src="/images/conf/kamera crveno precrtana.png"/></div>
                            )}
                        </div>
                    </div>
                )
            });

        const chats = this.state.chat.map((it, i) => {
            let message = it.msg;

            // URL validation
            if (ifContainsURL(message)){
                let tmp = message.split(" ")
                for (let i = 0; i < tmp.length; i++) {
                    if (isValidURL(tmp[i].trim())){
                        if(tmp[i].includes("http://")){
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        }else if (tmp[i].includes("https://")){
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        }else {
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="http://${tmp[i]}">${tmp[i]}</a>`)
                        }
                    }
                }
                message = tmp.join(" ")
            }

            // I am mentioned
            if (message.toString().includes(currentUserEmail)) {
                message = message.replace(new RegExp(`@${currentUserEmail}`, "g"), `<div class="being-mentioned">${currentUserEmail}</div>`)
                return (
                    <div className="chat-message-single" key={i}>
                        <div className="chat-user">{it.name}:<span className="chat-msg"
                                                                   dangerouslySetInnerHTML={{__html: message}}/></div>
                    </div>
                )
            }

            // I mentioned someone
            if (it.name === currentUserEmail) {
                let regex = new RegExp(currentUserEmail, "g")
                message = Object.keys(this.state.participants)
                    .reduce((memo, item) => memo.replace(new RegExp(`@${item}`, "g"), `<div class="i-mention-someone">${item}</div>`), message)
                return (
                    <div className="chat-message-single" key={i}>
                        <div className="chat-user">{it.name}:<span className="chat-msg"
                                                                   dangerouslySetInnerHTML={{__html: message}}/></div>
                    </div>
                )
            }

            // Render result
            return (
                <div className="chat-message-single" key={i}>
                    <div className="chat-user">{it.name}:<span className="chat-msg"/>{message}</div>
                </div>
            )
        });

        const audioInputSelect = this.state.audioInputSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const audioOutputSelect = this.state.audioOutputSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const videoSelect = this.state.videoSelect.map((it) => {
            return (<option value={it.id}>{it.value}</option>)
        });

        const autocompleteTags = Object.keys(this.state.participants)
            .map(item => {
                if (this.state.chatValue.includes("@")) {
                    let test = this.state.chatValue.split("@")
                    for (let i = 1; i < test.length - 1; i++) {
                        test[i] = "@" + test[i]
                    }
                    if (item.toLowerCase().includes(test[test.length - 1].toLowerCase())) {
                        return (
                            <span
                                onMouseEnter={() => this.setState({selectedSuggestedItem: item})}
                                onMouseLeave={() => this.setState({selectedSuggestedItem: ""})}
                                className={this.state.selectedSuggestedItem == item ? "autocomplete-hovered" : ""}
                                onClick={() => {
                                    test[test.length - 1] = "@" + item
                                    this.setState({showAutocomplete: false, chatValue: test.join("")})
                                }}>{item}</span>
                        )
                    }
                }
            });

        let previousMessages = getProp(this.props.resource, "detailsData.list", []).map((it, i) => {
            let message = it.msg;

            // URL validation
            if (ifContainsURL(message)){
                let tmp = message.split(" ")
                for (let i = 0; i < tmp.length; i++) {
                    if (isValidURL(tmp[i].trim())){
                        if(tmp[i].includes("http://")){
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        }else if (tmp[i].includes("https://")){
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="${tmp[i]}">${tmp[i]}</a>`)
                        }else {
                            tmp[i] = tmp[i].replace(tmp[i], `<a target="_blank" href="http://${tmp[i]}">${tmp[i]}</a>`)
                        }
                    }
                }
                message = tmp.join(" ")
            }
            return (
                <div className="chat-message-single" key={i}>
                    <div className="chat-user">{it.name}:<span className="chat-msg"
                                                               dangerouslySetInnerHTML={{__html: message}}/></div>
                </div>
            )
        });

        const numOfPresenters = this.props.resource?.data?.user ? Object.keys(presenters).length + (this.isPresenter() ? 1 : 0) : null

        const presenterVideos = !!numOfPresenters && !!this.state.screenResizeToggler && (numOfPresenters == 1) ? {
            display: "grid",
            gridTemplateColumns: `repeat(${numOfPresenters}, 1fr)`,
            gridGap: "10px",
        }
        : (numOfPresenters > 2 && numOfPresenters < 5) ? {
            display: "grid",
            gridTemplateColumns: `repeat(2, 1fr)`,
            gridTemplateRows: `repeat(2, 1fr)`,
            gridGap: "10px"
        } : (numOfPresenters == 2) ? {
            display: "grid",
            gridTemplateRows: `repeat(2, 1fr)`,
            gridGap: "10px"
        } : {
            display: "grid",
            gridTemplateColumns: `repeat(2, 1fr)`,
            gridTemplateRows: `repeat(${numOfPresenters/2}, 1fr)`,
            gridGap: "10px"
        }





        return (
            <React.Fragment>
                {!this.state.joinedRoom && (
                    <React.Fragment>
                        <div className="conference-entrance">
                            <div className="conference-entrance-link">
                                <h4>{!!LocalStorage.has("user") ?
                                    <span>{LocalStorage.get("user").Contact.user_fname} {LocalStorage.get("user").Contact.user_lname}</span> :
                                    <span>niste ulogovani</span>}</h4>
                            </div>
                            <div className="conference-entrance-logo">
                                <Link to="/" target={"_blank"}> <img alt="logo"
                                                                     src="/images/logo.png"/></Link>
                            </div>

                            <div className="conference-entrance-exit">
                                <img onClick={() => this.props.history.goBack()}
                                     alt="logo"
                                     src="/images/conf/go-back.jpg"/>
                            </div>
                            {!!this.state.speaking && (
                                <div className='voice-container'>
                                    <div className="voice-animation"></div>
                                </div>
                            )}
                        </div>
                        {currentUserRoleID == 1 && isVideoParticipantEnabled == 0 && (
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span
                                    style={{textAlign: "center"}}>{translate("text.participantVideoNotEnabled")}</span>
                            </div>
                        )}
                        <video
                            className={"preview-video"}
                            disablePictureInPicture
                            playsInline
                            muted={true}
                            autoPlay
                            ref={this.setupVideoRef}
                        />

                        <div className="container">
                            <div style={{marginTop: "30px"}}>
                                <h5>{translate("text.choose_mic")}</h5>
                                {currentUserRoleID == 1 && isAudioParticipantEnabled == 0 && (
                                    <span style={{}}>{translate("text.participantAudioNotEnabled")}</span>
                                )}
                                {!this.state.microphonePermission ? <span style={{color: "red"}}>Niste dozvolili pretraživaču da koristi Vaš mikrofon.</span> : ""}
                                <FieldSelect
                                    addClass={"form-control"}
                                    name={"audioInputSelect"}
                                    value={this.state.configuration.audioInputSelect}
                                    onChange={this.updateSetupStreamMedia}
                                >{audioInputSelect}
                                </FieldSelect>
                            </div>
                            <div style={{marginTop: "30px"}}>
                                <h5>{translate("text.choose_speaker")}</h5>
                                <FieldSelect
                                    addClass={"form-control"}
                                    name={"audioOutputSelect"}
                                    value={this.state.configuration.audioOutputSelect}
                                    onChange={this.updateSetupStreamMedia}
                                >{audioOutputSelect}
                                </FieldSelect>
                            </div>
                            <div style={{marginTop: "30px"}}>
                                <h5>{translate("text.choose_camera")}</h5>
                                {!this.state.cameraPermission ? <span style={{color: "red"}}>Niste dozvolili pretraživaču da koristi Vašu kameru.</span> : ""}
                                <FieldSelect
                                    addClass={"form-control"}
                                    name={"videoSelect"}
                                    value={this.state.configuration.videoSelect}
                                    onChange={this.updateSetupStreamMedia}
                                >{videoSelect}
                                </FieldSelect>
                            </div>
                            {!this.props.resource.isLoading && (
                                <div className='row' style={{
                                    marginTop: "30px",
                                    marginBottom: "100px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Button className="btn btn-success margin-auto" onClick={this.enterRoom}
                                            text={translate("enter_room")}></Button>
                                </div>
                            )}
                        </div>
                        {!!this.props.resource.isLoading && (
                            <LoaderSmall addClass={"text-center"}/>
                        )}

                        <div className="conference-my-controls">
                            <div className="conf-my-btn-wrapper">
                                {this.state.videoAvailable && (
                                    <React.Fragment>
                                        {currentUserRoleID == 1 && isVideoParticipantEnabled == 1 && (
                                            <div className="conf-icon-btn"
                                                 title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                 onClick={this.cameraToggle}>
                                                <img
                                                    src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                            </div>
                                        )}
                                        {currentUserRoleID != 1 && (
                                            <div className="conf-icon-btn"
                                                 title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                 onClick={this.cameraToggle}>
                                                <img
                                                    alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                    src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                                {this.state.audioAvailable && (
                                    <React.Fragment>
                                        {currentUserRoleID == 1 && isAudioParticipantEnabled == 1 && (
                                            <div className="conf-icon-btn"
                                                 title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                 onClick={this.micToggle}>
                                                <img
                                                    alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                    src={!this.state.mic ? ("/images/conf/mic_off.png") : ("/images/conf/mikrofon beli.png")}/>
                                            </div>
                                        )}

                                        {currentUserRoleID != 1 && (
                                            <div className="conf-icon-btn"
                                                 title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                 onClick={this.micToggle}>
                                                <img
                                                    alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                    src={!this.state.mic ? ("/images/conf/mic_off.png") : ("/images/conf/mikrofon beli.png")}/>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}

                            </div>
                        </div>
                    </React.Fragment>
                )}

                {this.state.joinedRoom && (
                    <React.Fragment>
                        <div className="room-header">

                            <div className="logo-img-wrapper">
                                <Link to="/" target={"_blank"}> <img alt="logo" src="/images/logo.png"/></Link>
                            </div>

                            <div className="room-name">
                                <h2>{this.props.resource.data.conference.ConferenceName} - {this.props.resource.data.room.RoomName}</h2>
                            </div>

                            <div className="room-options">
                                <div className="leave-room-btn" title={translate("text.leave_room")}
                                     onClick={() => {
                                         this.leaveRoom(true);
                                     }}>
                                    <img src="/images/conf/leave_conf1.png"/>
                                </div>
                            </div>
                        </div>
                        {this.state.loadingRoom && (
                            <div>
                                <LoaderSmall/>
                            </div>
                        )}

                        {!this.state.loadingRoom && (
                            <React.Fragment>
                                <div className={"conference-presentation"} style={{
                                    width: `${!!this.state.chatCollapse ? "80%" : "100%"}`
                                }}>
                                    <div className='grid-3'>
                                        <div></div>
                                        <h5>{translate("text.presentation")}</h5>
                                        <div>
                                            {(this.state.chatCollapse === false) && (
                                                <img onClick={() => this.setState({chatCollapse: true})}
                                                     className={`chat-collapse-img mt-10  ${!!this.state.addTilt ? "tilt":""}`}
                                                     src={`/images/chat_expand.png`} alt="chat expand"/>
                                            )}
                                        </div>
                                    </div>
                                    <div className='presentation-children' style={!!this.state.screenResizeToggler ? presenterVideos : {
                                        display: "grid",
                                        gridTemplateColumns: `repeat(${numOfPresenters}, 1fr)`,
                                        gridGap: "10px"
                                    }}>
                                        {this.isPresenter() && (
                                            <div className={
                                                (!this.state.screenResizeToggler ? "participant-single mystream":"")} style={{position: "relative"}}>
                                                <div id={"presenter"}>
                                                    <video
                                                        onClick={() => this.debug(this.getName())}
                                                        disablePictureInPicture
                                                        playsInline muted autoPlay ref={this.myVideoRef}
                                                        poster="/images/logo.png"
                                                        className={(document.fullscreenElement ? "h-100" : "") + ((!!this.state.screenResizeToggler && numOfPresenters > 1) ? "video-half-row":"video-whole-row")}
                                                    >
                                                    </video>

                                                    <div onClick={() => {
                                                        this.fullScreenToggle("presenter")
                                                    }} id="fullscreenbtn">
                                                        {document.fullscreenElement ?
                                                            <img title="Smanji ekran" style={{width: "40px"}}
                                                                 src="/images/conf/collapse.png" alt=""/>
                                                            :
                                                            <img title="Povecaj ekran"
                                                                 src="/images/conf/fullscreen.png" alt=""/>
                                                        }
                                                    </div>
                                                    {!!this.state.speaking && (
                                                        <div className="voice-container"
                                                             style={{bottom: document.fullscreenElement ? "65px" : "50px"}}>
                                                            <div className="voice-animation"></div>
                                                        </div>
                                                    )}
                                                    <div className="conference-my-controls ">
                                                        <div className="conf-my-btn-wrapper">

                                                            {/*{this.state.videoAvailable && (*/}
                                                            <React.Fragment>
                                                                {this.isPresenter() && (
                                                                    <div className="conf-icon-btn"
                                                                         title={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                                         onClick={this.screenShareToggle}>
                                                                        <img
                                                                            alt={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                                            src={!this.state.screenSharing ? ("/images/conf/stop_share_screen_new.png") : ("/images/conf/share_screen_new.png")}/>
                                                                    </div>
                                                                )}
                                                                {currentUserRoleID == 1 && isVideoParticipantEnabled == 1 && (
                                                                    <div className="conf-icon-btn"
                                                                         title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                         onClick={this.cameraToggle}>
                                                                        <img
                                                                            alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                            src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                                    </div>
                                                                )}
                                                                {currentUserRoleID != 1 && (
                                                                    <div className="conf-icon-btn"
                                                                         title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                         onClick={this.cameraToggle}>
                                                                        <img
                                                                            alt={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                                            src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                            {/*)}*/}
                                                            {this.state.audioAvailable && (
                                                                <React.Fragment>
                                                                    {currentUserRoleID == 1 && isAudioParticipantEnabled == 1 && (
                                                                        <div className="conf-icon-btn"
                                                                             title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                             onClick={this.micToggle}>
                                                                            <img
                                                                                alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                                src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                                        </div>
                                                                    )}
                                                                    {currentUserRoleID != 1 && (
                                                                        <div className="conf-icon-btn"
                                                                             title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                             onClick={this.micToggle}>
                                                                            <img
                                                                                alt={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                                                src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                                        </div>
                                                                    )}
                                                                </React.Fragment>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="participant-name">{this.getName()}</div>
                                            </div>
                                        )}
                                        {presenters}
                                    </div>
                                </div>
                                {this.state.chatCollapse && (
                                    <div
                                        className={`conference-chat ${window.innerWidth < 975 ? "chat-absolute" : ""}`}>
                                        <div className='flex-space-between'>
                                            <img onClick={() => this.setState({chatCollapse: false})}
                                                 className={`chat-collapse-img ${!this.state.chatCollapse ? "mt-10" : ""}`}
                                                 src={`/images/chat_${!!this.state.chatCollapse ? "collapse" : "expand"}.png`}
                                                 alt="chat collapse"
                                                 id={"image-tilt"}
                                            />
                                            {this.state.chatCollapse ? (
                                                <h2>{translate("text.welcome_to_live_chat")}</h2>) : (<h2></h2>)}
                                        </div>
                                        {/*<h2>Sl:{this.state.selectedSuggestedItem}</h2>*/}

                                        <>
                                            <div className="chat-wrapper" id="chat-wrapper">
                                                {previousMessages}
                                                {chats}
                                            </div>


                                            <div className="chat-send-message" style={{position: "relative"}}>
                                                <FieldTextarea
                                                    onClick={() => this.setState({emojisToggler: false})}
                                                    onKeyUp={event => {
                                                        if (event.keyCode == 9) {
                                                            let firstOne = document.querySelectorAll(".autocomplete")
                                                            if (!!firstOne[0]) {
                                                                if (!!this.state.showAutocomplete) {
                                                                    if (this.state.chatValue.includes("@")) {
                                                                        let array = this.state.chatValue.split("@")
                                                                        if (array.length > 0) {
                                                                            for (let i = 0; i < array.length; i++) {
                                                                                if (!!firstOne[0].childNodes[i] && firstOne[0].childNodes[i].innerHTML == this.state.selectedSuggestedItem) {
                                                                                    array[array.length - 1] = firstOne[0].childNodes[i].innerHTML
                                                                                }
                                                                            }
                                                                            if (this.state.selectedSuggestedItem == "") {
                                                                                array[array.length - 1] = firstOne[0]?.childNodes[0]?.innerHTML
                                                                                this.setState({selectedSuggestedItem: firstOne[0]?.childNodes[0]?.innerHTML})
                                                                            }
                                                                            this.setState({
                                                                                chatValue: array.join("@"),
                                                                                showAutocomplete: false
                                                                            })
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        if (event.target.value.slice(-1) == "@") {
                                                            this.setState({
                                                                showAutocomplete: true,
                                                                lastPositionOfMonkey: this.state.chatValue.length
                                                            })
                                                        }

                                                        if (event.keyCode == 27) {
                                                            this.setState({showAutocomplete: false})
                                                        }
                                                        if (event.keyCode == 13 && !event.shiftKey) {
                                                            if (this.state.chatValue.trim() !== "") {
                                                                this.sendChat(this.state.chatValue);
                                                                this.setState({
                                                                    chatValue: ""
                                                                })
                                                            }
                                                        }
                                                        if (event.target.value.slice(-1) == "@") {
                                                            if (!!this.state.showAutocomplete) {
                                                                let firstOne = document.querySelectorAll(".autocomplete")
                                                                if (!!firstOne[0]) {
                                                                    let arrayLen = firstOne[0].childNodes.length
                                                                    if (this.state.selectedSuggestedItem == "" && !!firstOne[0].childNodes[0]) {
                                                                        firstOne[0].childNodes[0].classList.add("autocomplete-hovered")
                                                                        this.setState({selectedSuggestedItem: firstOne[0].childNodes[0].innerHTML})
                                                                    } else {
                                                                        for (let i = 0; i < arrayLen; i++) {
                                                                            if (firstOne[0].childNodes[i].className == "autocomplete-hovered") {
                                                                                firstOne[0].childNodes[i].classList.remove('autocomplete-hovered')
                                                                                if (!!firstOne[0].childNodes[i + 1]) {
                                                                                    firstOne[0].childNodes[i + 1].classList.add('autocomplete-hovered')
                                                                                    this.setState({selectedSuggestedItem: firstOne[0].childNodes[i + 1].innerHTML})
                                                                                    break
                                                                                }
                                                                                if (i == (arrayLen - 1) && !!firstOne[0].childNodes[0]) {
                                                                                    firstOne[0].childNodes[0].classList.add('autocomplete-hovered')
                                                                                    this.setState({selectedSuggestedItem: firstOne[0].childNodes[0].innerHTML})
                                                                                    break
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                }
                                                            }
                                                        }
                                                        if (event.keyCode == 38) {
                                                            if (!!this.state.showAutocomplete) {
                                                                let firstOne = document.querySelectorAll(".autocomplete")
                                                                if (!!firstOne[0]) {
                                                                    let arrayLen = firstOne[0].childNodes.length
                                                                    if (this.state.selectedSuggestedItem == "" && !!firstOne[0].childNodes[arrayLen - 1]) {
                                                                        firstOne[0].childNodes[arrayLen - 1].classList.add("autocomplete-hovered")
                                                                        this.setState({selectedSuggestedItem: firstOne[0].childNodes[arrayLen - 1].innerHTML})
                                                                    } else {
                                                                        for (let i = 0; i < arrayLen; i++) {
                                                                            if (firstOne[0].childNodes[i].className == "autocomplete-hovered") {
                                                                                firstOne[0].childNodes[i].classList.remove('autocomplete-hovered')
                                                                                if (!!firstOne[0].childNodes[i - 1]) {
                                                                                    firstOne[0].childNodes[i - 1].classList.add('autocomplete-hovered')
                                                                                    this.setState({selectedSuggestedItem: firstOne[0].childNodes[i - 1].innerHTML})
                                                                                    break
                                                                                }
                                                                                if (i == 0 && !!firstOne[0].childNodes[0]) {
                                                                                    firstOne[0].childNodes[0].classList.add('autocomplete-hovered')
                                                                                    this.setState({selectedSuggestedItem: firstOne[0].childNodes[arrayLen - 1].innerHTML})
                                                                                    break
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    onKeyDown={event => {
                                                        if (event.keyCode == 9 || event.keyCode == 38 || event.keyCode == 40) {
                                                            event.preventDefault();

                                                        }
                                                    }}
                                                    placeholder={translate("text.write_something")}
                                                    value={this.state.chatValue}
                                                    onChange={(name, value) => {
                                                        this.setState({
                                                            chatValue: value
                                                        });
                                                        this.emoji(value)
                                                    }}
                                                />

                                                {this.state.showAutocomplete && (
                                                    <div ref={this.autocompleteDisplayRef} className='autocomplete'>
                                                        {autocompleteTags}
                                                    </div>
                                                )}
                                                <span
                                                    onClick={() => this.setState({emojisToggler: !this.state.emojisToggler})}
                                                    className="emoji-toggler">&#x1F600;</span>
                                                {!!this.state.emojisToggler && (
                                                    <div className="emojis-grid">
                                                        <div className='emoji-names'>
                                                            {Object.keys(emojis).map(item => (
                                                                <p onClick={() => this.setState({selectedEmojiGroup: item})}
                                                                   className={`names-of-emojis ${this.state.selectedEmojiGroup === item ? "active-emoji" : ""}`}>{emojis[item][0]['emoji']}</p>
                                                            ))}
                                                            <p onClick={() => this.setState({emojisToggler: false})}
                                                               className='names-of-emojis escape-emojis'>
                                                                <img src="/images/conf/escape.png" alt=""/>
                                                            </p>
                                                        </div>
                                                        <div className="emojis-container">
                                                            {Object.keys(emojis).map(item => {
                                                                if (item === this.state.selectedEmojiGroup) {
                                                                    return emojis[item].map(emoji => {
                                                                        return (<div onClick={() => {
                                                                            this.setState({chatValue: this.state.chatValue + emoji['emoji']}, () => {
                                                                                let tmp_value = this.state.chatValue
                                                                                tmp_value = tmp_value.replace("undefined", "")
                                                                                this.setState({chatValue: tmp_value})
                                                                            })

                                                                        }}>{emoji['emoji']}</div>)
                                                                    })
                                                                }
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    className={"conf-button"}
                                                    style={{cursor: this.state.chatValue == "" ? "alias" : "pointer"}}
                                                    onClick={() => {
                                                        if (this.state.chatValue.trim() !== "") {
                                                            this.sendChat(this.state.chatValue);
                                                            this.setState({
                                                                chatValue: "",
                                                                emojisToggler: false
                                                            })
                                                        }
                                                    }}
                                                    title={translate("text.send_message")}
                                                >
                                                    {this.state.chatValue == "" ?
                                                        <img alt={translate("text.send_message")}
                                                             src={"/images/cant_send_message.png"}/>
                                                        :
                                                        <img alt={translate("text.send_message")}
                                                             src={"/images/send_message.png"}/>
                                                    }
                                                </div>
                                            </div>
                                        </>

                                    </div>
                                )}
                                {!this.state.screenResizeToggler ?
                                    <div style={{display: "flex"}}>
                                        {/* desktop */}
                                        {!this.isPresenter() && (
                                            <React.Fragment>
                                                <div className="participant-single mystream my-stream-single">
                                                    <video
                                                        onClick={() => {
                                                            this.debug(this.getName())
                                                        }}
                                                        disablePictureInPicture
                                                        playsInline muted autoPlay ref={this.myVideoRef}
                                                        poster="/images/logo.png"
                                                    />
                                                    <div className="participant-name">{this.getName()}</div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        <div className={"conference-participants"}>
                                            {participants}
                                        </div>
                                    </div>
                                    :
                                    <div className={"conference-participants grid-repeat-2"}>
                                        {/* mobile */}
                                        {!this.isPresenter() && (
                                            <React.Fragment>
                                                <div className="participant-single mystream">
                                                    <video
                                                        onClick={() => {
                                                            this.debug(this.getName())
                                                        }}
                                                        poster="/images/logo.png"
                                                        disablePictureInPicture
                                                        playsInline muted autoPlay ref={this.myVideoRef}
                                                    />
                                                    <div className="participant-name">{this.getName()}</div>
                                                </div>
                                            </React.Fragment>
                                        )}
                                        {participants}
                                    </div>
                                }


                                {DEBUG && (this.state.steamStatsName == this.getName()) && !!this.state.streamStats && (
                                    <div className={"webrtc-debug"}>
                                        <div>
                                            {this.state.steamStatsName}
                                        </div>
                                        <div>
                                            <span>Send resolution </span>
                                            <span>{this.state.streamStats.resolutions.send.width} x {this.state.streamStats.resolutions.send.height}</span>
                                        </div>

                                        <div>
                                            <span>Bandwidth speed </span>
                                            <span>{this.state.streamStats.bandwidth.speed}</span>
                                        </div>
                                        <div>
                                            <span>Video </span>
                                            <span>Sent: {formatBytes(this.state.streamStats.video.bytesSent)}</span>
                                        </div>
                                        <div>
                                            <span>Audio </span>
                                            <span>Sent: {formatBytes(this.state.streamStats.audio.bytesSent)}</span>
                                        </div>
                                    </div>
                                )}

                                {DEBUG && (this.state.steamStatsName != this.getName()) && !!this.state.streamStats && (
                                    <div className={"webrtc-debug"}>
                                        <div>
                                            {this.state.steamStatsName}
                                        </div>
                                        <div>
                                            <span>Receive resolution </span>
                                            <span>{this.state.streamStats.resolutions.recv.width} x {this.state.streamStats.resolutions.recv.height}</span>
                                        </div>

                                        <div>
                                            <span>Bandwidth speed </span>
                                            <span>{this.state.streamStats.bandwidth.speed}</span>
                                        </div>
                                        <div>
                                            <span>Video </span>
                                            <span>Received: {formatBytes(this.state.streamStats.video.bytesReceived)}</span>
                                        </div>
                                        <div>
                                            <span>Audio </span>
                                            <span>Received: {formatBytes(this.state.streamStats.audio.bytesReceived)}</span>
                                        </div>
                                    </div>
                                )}

                                <div className="conference-my-controls alo">
                                    <div className="conf-my-btn-wrapper">
                                        {isAudioParticipantEnabled == 0 && isVideoParticipantEnabled == 0 && !this.isPresenter() && (
                                            <p style={{color: "#fff"}}>Video i kamera nisu dozvoljeni korisnicima</p>
                                        )}
                                        {this.isPresenter() && (
                                            <div className="conf-icon-btn"
                                                 title={!this.state.screenSharing ? translate("text.screen_share_on") : translate("text.screen_share_off")}
                                                 onClick={this.screenShareToggle}>
                                                <img
                                                    src={!this.state.screenSharing ? ("/images/conf/stop_share_screen_new.png") : ("/images/conf/share_screen_new.png")}/>
                                            </div>
                                        )}
                                        {this.state.videoAvailable && (
                                            <React.Fragment>

                                                {currentUserRoleID == 1 && isVideoParticipantEnabled == 1 && (
                                                    <div className="conf-icon-btn"
                                                         title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                         onClick={this.cameraToggle}>
                                                        <img
                                                            src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                    </div>
                                                )}
                                                {currentUserRoleID != 1 && (
                                                    <div className="conf-icon-btn"
                                                         title={!this.state.camera ? translate("text.camera_on") : translate("text.camera_off")}
                                                         onClick={this.cameraToggle}>
                                                        <img
                                                            src={!this.state.camera ? ("/images/conf/kamera crveno precrtana.png") : ("/images/conf/video_on.png")}/>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}

                                        {this.state.audioAvailable && (
                                            <React.Fragment>
                                                {currentUserRoleID == 1 && isAudioParticipantEnabled == 1 && (
                                                    <div className="conf-icon-btn"
                                                         title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                         onClick={this.micToggle}>
                                                        <img
                                                            src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                    </div>
                                                )}
                                                {currentUserRoleID != 1 && (
                                                    <div className="conf-icon-btn"
                                                         title={!this.state.mic ? translate("text.mic_on") : translate("text.mic_off")}
                                                         onClick={this.micToggle}>
                                                        <img
                                                            src={!this.state.mic ? ("/images/conf/mikrofon crveni precrtan.png") : ("/images/conf/mikrofon beli.png")}/>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default connect(state => state)(RoomView);